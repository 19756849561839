import 'default-passive-events'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import Element from 'element-ui'
// import './plugins/axios'
import md5 from 'js-md5';
import {VueJsonp} from 'vue-jsonp'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element)
Vue.use(VueJsonp)
Vue.prototype.$md5 = md5;
Vue.prototype.h = 2;
Vue.config.productionTip = false
import {Message} from 'element-ui'

Vue.prototype.$http = axios

axios.defaults.baseURL = process.env.VUE_APP_BASE_API
// axios.defaults.baseURL = 'http://localhost:8888/api'
axios.defaults.withCredentials = true

// 响应拦截
axios.interceptors.response.use(function (response) {
    if (response.response && response.response.data.code === 401) {
        window.localStorage.removeItem("token")
        router.push("/login").then(r =>{
            Message.error("登录失效")
        });
    }
    if (response.data.code === 5104) {
        Message.error("登录授权异常，请重新登录")
        setTimeout(()=>{
            window.localStorage.removeItem("token")
            router.push("/login").then(() =>{
            });
        },2000)

    }

// 这个是处理响应成功的结果，也就是说响应状态码为2XX系列的
    return response;
},function (error) {
// 这个是处理响应失败的，也就是响应错误的，在这个箭头函数中，才能拿到401状态下的包
    return Promise.reject(error);
});


/*
axios.interceptors.response.use((data) => {
    console.log("success:", data)
    if (data.data.code === 200) {
        return data
    }
    if (data.data.code === 401) {
        /!*window.localStorage.removeItem("token")
        router.push("/login")
        return *!/
        Message.error("错误")
    }
}, error => {
    debugger
    console.log("error:", error)
    if (error.response.status === 401) {
        // 接口的根地址截取下来，判断
        const url = error.response.config.url
        const flag = url.substring(0, url.indexOf('/'))
        // 判断地址是哪一个页面的，push到相应的错误页面
        if (flag == 'roster') {
            router.push({
                path: '/rosterError',
                query: {
                    info: error.response.data.message
                }
            })
        }
        if (flag == 'user') {
            router.push({
                path: '/AllUserinfoEerror',
                query: {
                    info: error.response.data.message
                }
            })
        }
    }

})*/
//验证手机号
Vue.prototype.checkPhone = (phone) => {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
        return false;
    }
    return true;
}

// 添加请求拦截器
axios.interceptors.request.use((config) => {
    // 添加header头的token
    let token = window.localStorage.getItem('token')
    if (token) {
        config.headers.common['token'] = token;
    }

    // 在发送请求之前做些什么
    return config;
}, (error) => {

    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
    if (response.data.code == 5000) {
        return Message.error("系统异常")
    } else {
        return response
    }
}, (err) => {
    // 全局错误提示
    if (err.response && err.response.data && err.response.data.errorCode) {
        Message.error(err.response.data.msg)
    }

    // 对响应错误做点什么
    return Promise.reject(err);
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
